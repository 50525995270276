import { FormsFieldPreset, customTypes  } from '../../../../../../constants/field-types'
import { FieldCollectionType } from '../../../../../../constants/wixcode'
import { FIELDS } from '../../../../../../constants/roles'
import { DEFAULT_PLACEHOLDER } from '../constants'
import { COMPONENT_TYPES } from '../../component-types'
import translations from '../../../../../../utils/translations'
import { FieldProperties, FieldData } from '../../field-types-data'
import { CRM_TYPES } from '../../../../../../constants/crm-types-tags'
import { iconNames } from '../../icons/types'
import { FormPlugin } from '../../../../../../constants/plugins'

const makeGeneralTextBox = (): FieldProperties => {
  const t = translations.t.bind(translations)

  return {
    collectionFieldType: FieldCollectionType.TEXT,
    componentType: COMPONENT_TYPES.TEXT_AREA_INPUT,
    extraData: {
      role: FIELDS.ROLE_FIELD_TEXT_AREA,
      connectionConfig: {
        crmType: CRM_TYPES.CUSTOM_FIELD,
        crmLabel: t(`fieldTypes.${FormsFieldPreset.GENERAL_TEXT_BOX}`),
      },
      props: {
        placeholder: t(DEFAULT_PLACEHOLDER),
      },
      data: {
        placeholder: t(DEFAULT_PLACEHOLDER),
      }
    },
  }
}

export const generalTextBoxData: FieldData = {
  make: makeGeneralTextBox,
  customFields: [customTypes.TEXT],
  icon: iconNames.paragraph,
  titleTranslationKey: `adi.${FormsFieldPreset.GENERAL_TEXT_BOX}FieldEditPanel.title`,
  pluginsExtraData: {
    [FormPlugin.REGISTRATION_FORM]: {
      layout: {
        width: 240
      }
    }
  }
}

