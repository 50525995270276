import * as _ from 'lodash'
import { FormsFieldPreset } from '../../../../../../constants/field-types'
import translations from '../../../../../../utils/translations'
import { FieldProperties, FieldData } from '../../field-types-data'
import { emailData } from '../../recommended-fields/definitions/email'

const makeAdiEmail = (): FieldProperties => {
  const t = translations.t.bind(translations)

  return _.merge(emailData.make(), {
    extraData: {
      data: {
        placeholder: t(`adi.fieldTypes.${FormsFieldPreset.EMAIL}.placeholder`),
        label: t(`adi.fieldTypes.${FormsFieldPreset.EMAIL}.label`),
      },
      props: { placeholder: t(`adi.fieldTypes.${FormsFieldPreset.EMAIL}.placeholder`) },
    },
  })
}

export const adiEmailData: FieldData = {
  ...emailData,
  make: makeAdiEmail,
}
