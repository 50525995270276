import { FormsFieldPreset, RegistrationFieldPreset, AdiFieldPreset, FieldPreset } from '../../../constants/field-types'

const {
  ANNIVERSARY,
  BIRTHDAY,
  CRM_DATE,
  GENERAL_DATE_PICKER,
  GENERAL_SINGLE_CHECKBOX,
  GENERAL_SUBSCRIBE,
  GENERAL_CHECKBOX,
  GENERAL_RADIO_BUTTON,
  GENERAL_DROP_DOWN
} = FormsFieldPreset

const { REGISTRATION_FORM_CHECKBOX_AGREE_TERMS } = RegistrationFieldPreset

const { ADI_DATE } = AdiFieldPreset

export const DATE_FIELD_PRESETS: FieldPreset[] = [
  ANNIVERSARY,
  BIRTHDAY,
  CRM_DATE,
  GENERAL_DATE_PICKER,
  ADI_DATE,
]
export const SINGLE_CHECKBOX_PRESETS: FieldPreset[] = [
  GENERAL_SINGLE_CHECKBOX,
  REGISTRATION_FORM_CHECKBOX_AGREE_TERMS,
]
export const FILE_UPLOAD_FIELD_PRESET: FieldPreset[] = [AdiFieldPreset.ADI_UPLOAD_BUTTON]
export const FIELDS_WITHOUT_LABEL_TOGGLE: FieldPreset[] = [
  GENERAL_SINGLE_CHECKBOX,
  GENERAL_SUBSCRIBE,
  GENERAL_CHECKBOX,
  GENERAL_RADIO_BUTTON,
]
export const OPTION_FIELD_PRESETS: FieldPreset[] = [
  GENERAL_RADIO_BUTTON,
  AdiFieldPreset.ADI_GENERAL_DROPDOWN,
  GENERAL_DROP_DOWN,
  GENERAL_CHECKBOX,
]
