import translations from '../../../utils/translations'
import { ROLE_FORM } from '../../../constants/roles'
import { GFPP } from './manifest-commons'
import { AppStateObject } from '../app-state/app-state-builder'
import * as _ from 'lodash'
import { FormPlugin } from '../../../constants/plugins'
import { MessageType } from '../../../constants/field-types'

const KEY_BY_MESSAGE_TYPE = {
  [MessageType.SUCCESS]: 'hiddenMessage',
  [MessageType.DOWNLOAD]: 'downloadFileMessage',
  [MessageType.REGISTRATION]: 'registrationFormMessage',
}

export const createHiddenMessageManifest = ({ plugins }: AppStateObject = {}, messageType: MessageType = MessageType.SUCCESS) => {
  if (_.includes(plugins, FormPlugin.REGISTRATION_FORM)) {
    messageType = MessageType.REGISTRATION
  }
  const messageKey = KEY_BY_MESSAGE_TYPE[messageType]

  return {
    relatedToRole: ROLE_FORM,
    displayName: translations.t(
      `manifest.${messageKey}.displayName`
    ),
    behavior: { duplicatable: false },
    confirmOnDelete: {
      title: translations.t(
        `manifest.${messageKey}.confirmOnDelete.title`
      ),
      contentText: translations.t(
        `manifest.${messageKey}.confirmOnDelete.contentText`
      ),
    },
    gfpp: {
      desktop: {
        iconButtons: {
          connect: GFPP.REMOVE,
        },
      },
    },
  }
}
