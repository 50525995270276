import * as _ from 'lodash'
import { FormsFieldPreset } from '../../../../../../constants/field-types'
import translations from '../../../../../../utils/translations'
import { FieldProperties, FieldData } from '../../field-types-data'
import { lastNameData } from '../../recommended-fields/definitions/last-name'

const makeAdiLastName = (): FieldProperties => {
  const t = translations.t.bind(translations)

  return _.merge(lastNameData.make(), {
    extraData: {
      props: {
        placeholder: t(`adi.fieldTypes.${FormsFieldPreset.LAST_NAME}.placeholder`),
        required: true,
      },
      data: {
        placeholder: t(`adi.fieldTypes.${FormsFieldPreset.LAST_NAME}.placeholder`),
        label: t(`adi.fieldTypes.${FormsFieldPreset.LAST_NAME}.label`),
      },
    },
  })
}

export const adiLastNameData: FieldData = {
  ...lastNameData,
  make: makeAdiLastName,
}
