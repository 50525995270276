import * as _ from 'lodash'
import { FormsFieldPreset } from '../../../../../../constants/field-types'
import translations from '../../../../../../utils/translations'
import { FieldProperties, FieldData } from '../../field-types-data'
import { generalDropdownData } from '../../general-fields/definitions/general-dropdown'

const makeAdiDropdown = (): FieldProperties => {
  const t = translations.t.bind(translations)

  return _.merge(generalDropdownData.make(), {
    extraData: {
      props: {
        placeholder: {
          value: t(`adi.fieldTypes.${FormsFieldPreset.GENERAL_DROP_DOWN}.placeholder`),
          text: t(`adi.fieldTypes.${FormsFieldPreset.GENERAL_DROP_DOWN}.placeholder`),
        },
      },
      data: {
        placeholder: {
          value: t(`adi.fieldTypes.${FormsFieldPreset.GENERAL_DROP_DOWN}.placeholder`),
          text: t(`adi.fieldTypes.${FormsFieldPreset.GENERAL_DROP_DOWN}.placeholder`),
        },
        label: t(`adi.fieldTypes.${FormsFieldPreset.GENERAL_DROP_DOWN}.label`),
      },
    },
  })
}

export const adiGeneralDropdown: FieldData = {
  ...generalDropdownData,
  make: makeAdiDropdown,
}
