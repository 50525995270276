import { FormsFieldPreset } from '../../../../../constants/field-types'
import { generalTextData } from './definitions/general-text'
import { generalNumberData } from './definitions/general-number'
import { generalUrlData } from './definitions/general-url'
import { generalRadioButtonData } from './definitions/general-radio-group'
import { generalDatePickerData } from './definitions/general-date-picker'
import { generalDropdownData } from './definitions/general-dropdown'
import { generalUploadButtonData } from './definitions/general-upload-button'
import { generalCheckboxData } from './definitions/general-checkbox'
import { generalSingleCheckboxData } from './definitions/general-single-checkbox'
import { generalTextBoxData } from './definitions/general-text-box'
import { generalRatingData } from './definitions/general-rating'
import { generalRecaptchaData } from './definitions/general-recaptcha'
import { generalSubscribeData } from './definitions/general-subscribe'

export const generalFields = () => ({
  [FormsFieldPreset.GENERAL_TEXT]: generalTextData,
  [FormsFieldPreset.GENERAL_NUMBER]: generalNumberData,
  [FormsFieldPreset.GENERAL_URL]: generalUrlData,
  [FormsFieldPreset.GENERAL_RADIO_BUTTON]: generalRadioButtonData,
  [FormsFieldPreset.GENERAL_DATE_PICKER]: generalDatePickerData,
  [FormsFieldPreset.GENERAL_DROP_DOWN]: generalDropdownData,
  [FormsFieldPreset.GENERAL_UPLOAD_BUTTON]: generalUploadButtonData,
  [FormsFieldPreset.GENERAL_CHECKBOX]: generalCheckboxData,
  [FormsFieldPreset.GENERAL_SINGLE_CHECKBOX]: generalSingleCheckboxData,
  [FormsFieldPreset.GENERAL_SUBSCRIBE]: generalSubscribeData,
  [FormsFieldPreset.GENERAL_TEXT_BOX]: generalTextBoxData,
  [FormsFieldPreset.GENERAL_RATING]: generalRatingData,
  [FormsFieldPreset.GENERAL_RECAPTCHA]: generalRecaptchaData,
})
