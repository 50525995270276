import { FormsFieldPreset, customTypes  } from '../../../../../../constants/field-types'
import { FieldCollectionType } from '../../../../../../constants/wixcode'
import { FIELDS } from '../../../../../../constants/roles'
import { COMPONENT_TYPES } from '../../component-types'
import { DEFAULT_PLACEHOLDER } from '../constants'
import translations from '../../../../../../utils/translations'
import { FieldProperties, FieldData } from '../../field-types-data'
import { CRM_TYPES } from '../../../../../../constants/crm-types-tags'
import { iconNames } from '../../icons/types'

const makeGeneralNumber = (): FieldProperties => {
  const t = translations.t.bind(translations)

  return {
    collectionFieldType: FieldCollectionType.NUMBER,
    componentType: COMPONENT_TYPES.TEXT_INPUT,
    extraData: {
      role: FIELDS.ROLE_FIELD_TEXT,
      connectionConfig: {
        crmType: CRM_TYPES.CUSTOM_FIELD,
        crmLabel: t(`fieldTypes.${FormsFieldPreset.GENERAL_NUMBER}`),
      },
      data: {
        textType: 'number',
        placeholder: t(DEFAULT_PLACEHOLDER),
      },
      props: {
        placeholder: t(DEFAULT_PLACEHOLDER),
      },
    },
  }
}

export const generalNumberData: FieldData = {
  make: makeGeneralNumber,
  customFields: [customTypes.NUMBER],
  icon: iconNames.number,
  titleTranslationKey: `adi.${FormsFieldPreset.GENERAL_NUMBER}FieldEditPanel.title`
}
