import * as _ from 'lodash'
import { generalDatePickerData } from '../../general-fields/definitions/general-date-picker'
import { FieldProperties, FieldData } from '../../field-types-data'
import { CRM_TYPES } from '../../../../../../constants/crm-types-tags'

const makeCrmDate = (): FieldProperties => {
  return _.merge(generalDatePickerData.make(), {
    extraData: {
      connectionConfig: {
        crmType: CRM_TYPES.CUSTOM_FIELD,
      },
    },
  })
}

export const crmDateData: FieldData = {
  make: makeCrmDate,
  customFields: generalDatePickerData.customFields,
  icon: generalDatePickerData.icon
}
