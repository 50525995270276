import * as _ from 'lodash'
import { createFormContainerManifest } from './box-manifest'
import { createFieldsManifests } from './field-manifest'
import {
  FIELDS_ROLES,
  ROLE_DOWNLOAD_MESSAGE,
  ROLE_FORM,
  ROLE_MESSAGE,
  ROLE_SUBMIT_BUTTON,
  ROLE_TITLE,
  ROLE_PREVIOUS_BUTTON,
  ROLE_NEXT_BUTTON,
} from '../../../constants/roles'
import { createSubmitButtonManifest } from './submit-button-manifest'
import { createHiddenMessageManifest } from './hidden-message-manifest'
import { createTitleManifest } from './title-manifest'
import { AppStateBuilder, AppStateObject } from '../app-state/app-state-builder'
import { FormPlugin } from '../../../constants/plugins'
import { MessageType } from '../../../constants/field-types'
import { ControllerType } from '../api-types'
import { createMultiStepManifest } from './multi-step-manifest'
import { createFirstTimeExperienceManifest } from './first-time-experience-manifest'
import { stepButtonManifest } from './step-button-manifest'

const ALWAYS_HIDE_CONTROLLER = 'NEVER'
const plugins = [
  FormPlugin.FORM_BUILDER,
  FormPlugin.GET_SUBSCRIBERS,
  FormPlugin.REGISTRATION_FORM,
  FormPlugin.MULTI_STEP_FORM,
]
const states = _.flatMap(plugins, plugin =>
  _.flatMap(
    [true, false],
    duplicatable =>
      new AppStateBuilder({
        duplicatable,
        plugins: [plugin],
      })
  )
).filter(x => x.toString())

const statesByPlugin: { [key in FormPlugin]?: AppStateBuilder[] } = plugins.reduce(
  (acc, plugin: FormPlugin) => {
    acc[plugin] = states.filter(appState => _.includes(appState.get().plugins, plugin))
    return acc
  },
  {}
)

export const getAppManifest = ({
  connectFieldGfpp,
  isTopPremium,
  replaceManageFieldsWithAddFieldGffp,
  shouldEnableCopyPasteFields,
}) => {
  const enhanceState = state => ({
    ...state,
    connectFieldGfpp,
    isTopPremium,
    replaceManageFieldsWithAddFieldGffp,
    shouldEnableCopyPasteFields,
  })

  const createFieldManifests = state => {
    return FIELDS_ROLES.reduce(
      (res, fieldRole) => ({
        ...res,
        [fieldRole]: createFieldsManifests(state)[fieldRole],
      }),
      {}
    )
  }

  const titleManifest = createTitleManifest()
  const createManifest = (appState: AppStateObject) => {
    const state = enhanceState(appState)
    const isMultiStepForm = _.includes(state.plugins, FormPlugin.MULTI_STEP_FORM)
    const { box, steps } = isMultiStepForm
      ? createMultiStepManifest(state)
      : createFormContainerManifest(state)

    let pluginsMainRoles = {}

    if (isMultiStepForm) {
      pluginsMainRoles = {
        [ROLE_PREVIOUS_BUTTON]: stepButtonManifest(ROLE_PREVIOUS_BUTTON),
        [ROLE_NEXT_BUTTON]: stepButtonManifest(ROLE_NEXT_BUTTON),
      }
    }

    return {
      visibility: ALWAYS_HIDE_CONTROLLER,
      ...box,
      connections: {
        [ROLE_FORM]: box,
        [ROLE_SUBMIT_BUTTON]: createSubmitButtonManifest(state),
        [ROLE_MESSAGE]: createHiddenMessageManifest(state),
        [ROLE_DOWNLOAD_MESSAGE]: createHiddenMessageManifest(state, MessageType.DOWNLOAD),
        [ROLE_TITLE]: titleManifest,
        ...pluginsMainRoles,
        ...steps,
        ...createFieldManifests(state),
      },
      ...createFirstTimeExperienceManifest(state),
    }
  }

  const createDefaultStateWithStates = states => ({
    default: createManifest({}),
    ...states.reduce((agg, state) => {
      agg[state.toString()] = createManifest(state.get())
      return agg
    }, {}),
  })

  return {
    controllersStageData: {
      singlePostController: createDefaultStateWithStates(states),
      [ControllerType.WIX_FORMS]: createDefaultStateWithStates(
        statesByPlugin[FormPlugin.FORM_BUILDER]
      ),
      [ControllerType.GET_SUBSCRIBERS]: createDefaultStateWithStates(
        statesByPlugin[FormPlugin.GET_SUBSCRIBERS]
      ),
      [ControllerType.REGISTRATION_FORM]: createDefaultStateWithStates(
        statesByPlugin[FormPlugin.REGISTRATION_FORM]
      ),
      [ControllerType.MULTI_STEP_FORM]: createDefaultStateWithStates(
        statesByPlugin[FormPlugin.MULTI_STEP_FORM]
      ),
    },
  }
}
