import { FIELDS } from '../../../../../../constants/roles'
import { RegistrationFieldPreset } from '../../../../../../constants/field-types'
import translations from '../../../../../../utils/translations'
import { FieldProperties, FieldData } from '../../field-types-data'
import { checkboxAgreeTermsData } from './checkbox-agree-terms'
import * as _ from 'lodash'

const makeCodeOfConduct = (): FieldProperties => {
  const t = translations.t.bind(translations)

  return _.merge({}, checkboxAgreeTermsData.make(), {
    extraData: {
      role: FIELDS.ROLE_FIELD_REGISTRATION_FORM_CODE_OF_CONDUCT,
      connectionConfig: {
        crmLabel: t(`fieldTypes.${RegistrationFieldPreset.REGISTRATION_FORM_CODE_OF_CONDUCT}`),
      },
      data: {
        label: t(`preset.${_.camelCase(RegistrationFieldPreset.REGISTRATION_FORM_CODE_OF_CONDUCT)}Label`),
      },
    },
  })
}

export const codeOfConductData: FieldData = _.merge({}, checkboxAgreeTermsData, {
  make: makeCodeOfConduct,
})
