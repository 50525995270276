import * as _ from 'lodash'
import { ADI_FIELDS_WITHOUT_LABEL_TOGGLE } from './components/edit-form-panel/constants'
import { FormField, FieldOption } from '../../editor-app/core/api-types'
import { PremiumRestriction } from '../../constants/premium'
import { FieldPreset } from '../../constants/field-types'
import {
  DATE_FIELD_PRESETS,
  SINGLE_CHECKBOX_PRESETS,
  FILE_UPLOAD_FIELD_PRESET,
  FIELDS_WITHOUT_LABEL_TOGGLE,
  OPTION_FIELD_PRESETS,
} from './constants/fields-groups'

const escapeRegExpSpecialCharcters = str =>
  str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')

const getDuplicateSuffix = (value: string, existingValues: string[], suffixTemplate: string) => {
  const templateParts = suffixTemplate ? suffixTemplate.split('{0}') : []
  const templateStart = templateParts[0] || '_'
  const templateEnd = templateParts[1] || ''
  const duplicateValueRegExp = new RegExp(
    `${escapeRegExpSpecialCharcters(value + templateStart)}(\\d+)${escapeRegExpSpecialCharcters(
      templateEnd
    )}`
  )

  const duplicateNumber =
    _(existingValues)
      .map(currValue => {
        const valueToTest =
          value === currValue ? `${currValue + templateStart}0${templateEnd}` : currValue
        const valueMatch = valueToTest.match(duplicateValueRegExp)

        return valueMatch ? parseInt(valueMatch[1], 10) : null
      })
      .filter(currValue => currValue !== null)
      .max() + 1

  return templateStart + duplicateNumber + templateEnd
}

const getUniqueValue = (value: string, existingValues: string[], suffixTemplate: string) => {
  if (!_.includes(existingValues, value)) {
    return value
  }

  return value + getDuplicateSuffix(value, existingValues, suffixTemplate)
}

export const filterNonLabelFields = (fields: Array<FormField>) => {
  return _.filter(
    fields,
    item => !_.values(ADI_FIELDS_WITHOUT_LABEL_TOGGLE).includes(item.fieldType)
  )
}

export const getFieldsLeft = (fields: Array<FormField>, restrictions: PremiumRestriction) => {
  const { limit } = restrictions.fields
  return limit > -1 ? limit - fields.length : Infinity
}

export const shouldShowFieldLabel = (fieldType: FieldPreset) =>
  FIELDS_WITHOUT_LABEL_TOGGLE.includes(fieldType)

export const isDateField = (fieldType: FieldPreset) => DATE_FIELD_PRESETS.includes(fieldType)
export const isSingleCheckbox = (fieldType: FieldPreset) =>
  SINGLE_CHECKBOX_PRESETS.includes(fieldType)
export const isFileUploader = (fieldType: FieldPreset) =>
  FILE_UPLOAD_FIELD_PRESET.includes(fieldType)
export const isFieldWithOptions = (fieldType: FieldPreset) =>
  OPTION_FIELD_PRESETS.includes(fieldType)

export const isFieldHasComplexPlaceholder = (field: FormField) => !!_.get(field, 'placeholder.text')
export const isCheckboxOption = fielOption => fielOption.checked !== undefined
export const createOptionValue = (options: FieldOption[], label: string) => {
  const SUFFIX_TEMPLATE = ' {0}'
  const existingValues = _.map(options, 'value')
  existingValues.push(label)

  return getUniqueValue(label, existingValues, SUFFIX_TEMPLATE)
}

export const getFieldName = fieldProperties => {
  const { label, placeholder, buttonLabel, showLabel, crmLabel, fieldType } = fieldProperties
  const realPlaceholder = isFileUploader(fieldType)
    ? buttonLabel
    : _.get(placeholder, 'text') || placeholder
  const labelTrimmed = _.trim(label)
  const placeholderTrimmed = _.trim(realPlaceholder)
  return (showLabel && labelTrimmed) || placeholderTrimmed || crmLabel
}
