import { AdiFieldPreset } from '../../../../../constants/field-types'
import { adiAddressData } from './definitions/adi-address'
import { adiPhoneData } from './definitions/adi-phone'
import { adiEmailData } from './definitions/adi-email'
import { adiURLData } from './definitions/adi-url'
import { adiNumberData } from './definitions/adi-number'
import { adiDateData } from './definitions/adi-date'
import { adiFirstNameData } from './definitions/adi-first-name'
import { adiLastNameData } from './definitions/adi-last-name'
import { adiGeneralTextData } from './definitions/adi-general-text'
import { adiGeneralTextBoxData } from './definitions/adi-general-text-box'
import { adiGeneralUploadButton } from './definitions/adi-general-upload-button'
import { adiGeneralDropdown } from './definitions/adi-general-dropdown'

export const adiFormFields = () => ({
  [AdiFieldPreset.ADI_EMAIL]: adiEmailData,
  [AdiFieldPreset.ADI_PHONE]: adiPhoneData,
  [AdiFieldPreset.ADI_ADDRESS]: adiAddressData,
  [AdiFieldPreset.ADI_URL]: adiURLData,
  [AdiFieldPreset.ADI_DATE]: adiDateData,
  [AdiFieldPreset.ADI_NUMBER]: adiNumberData,
  [AdiFieldPreset.ADI_FIRST_NAME]: adiFirstNameData,
  [AdiFieldPreset.ADI_LAST_NAME]: adiLastNameData,
  [AdiFieldPreset.ADI_GENERAL_TEXT]: adiGeneralTextData,
  [AdiFieldPreset.ADI_GENERAL_TEXT_BOX]: adiGeneralTextBoxData,
  [AdiFieldPreset.ADI_UPLOAD_BUTTON]: adiGeneralUploadButton,
  [AdiFieldPreset.ADI_GENERAL_DROPDOWN]: adiGeneralDropdown
})
