import * as _ from 'lodash'
import { FormsFieldPreset } from '../../../../../../constants/field-types'
import translations from '../../../../../../utils/translations'
import { FieldProperties, FieldData } from '../../field-types-data'
import { firstNameData } from '../../recommended-fields/definitions/first-name'

const makeAdiFirstName = (): FieldProperties => {
  const t = translations.t.bind(translations)

  return _.merge(firstNameData.make(), {
    extraData: {
      props: {
        placeholder: t(`adi.fieldTypes.${FormsFieldPreset.FIRST_NAME}.placeholder`),
        required: true,
      },
      data: {
        placeholder: t(`adi.fieldTypes.${FormsFieldPreset.FIRST_NAME}.placeholder`),
        label: t(`adi.fieldTypes.${FormsFieldPreset.FIRST_NAME}.label`),
      },
    },
  })
}

export const adiFirstNameData: FieldData = {
  ...firstNameData,
  make: makeAdiFirstName,
}
