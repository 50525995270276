import translations from '../../../utils/translations'
import { CRM_TAGS, CRM_TYPES, crmTypesTags} from '../../../constants/crm-types-tags'
import * as _ from 'lodash'
import { getFieldProperties } from '../preset/fields/field-types-data'
import { createSuffixedName } from '../../../utils/utils'
import { ComponentConfig, FormField } from '../api-types'

export const getDuplicatedFieldConfig = (fields: Partial<FormField>[], config: ComponentConfig): ComponentConfig => {
  const duplicatedFieldName = translations.t('manageFields.duplicatedFormName', {
    name: config.crmLabel,
  })
  const newCrmLabel = createSuffixedName(
    _.map(fields, (field: Partial<FormField>) => field.crmLabel),
    duplicatedFieldName
  )
  const isCrmTypeTag = !!crmTypesTags[config.crmType]

  return _.assign({}, config, {
    collectionFieldType: getFieldProperties(config.fieldType).collectionFieldType,
    crmLabel: newCrmLabel,
    crmTag: isCrmTypeTag ? CRM_TAGS.OTHER : config.crmTag,
    crmType: isCrmTypeTag ? config.crmType : CRM_TYPES.CUSTOM_FIELD,
    customFieldId: undefined,
    customFieldName: undefined,
    collectionFieldKey: createSuffixedName(
      _.map(fields, 'collectionFieldKey'),
      _.camelCase(newCrmLabel),
      ''
    )
  })
}
