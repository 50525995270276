import * as _ from 'lodash'
import { FIELDS } from '../../../constants/roles'
import { FormPreset } from '../../../constants/form-types'

type CustomStyleHandler = { style?, text }

//TODO: Remove get_subscribers custom handler when we will complete form-builder-resources migration
export const customStyleHandlers = {
  [FIELDS.ROLE_FIELD_REGISTRATION_FORM_LINK_TO_LOGIN_DIALOG]: ({ style, text }: CustomStyleHandler) =>
    _.replace(style, 'TITLE', text),
  [FormPreset.GET_SUBSCRIBERS]: ({ text }: CustomStyleHandler) => text,
}
