import * as _ from 'lodash'
import { RegistrationFieldPreset } from '../../../../../../constants/field-types'
import { FIELDS } from '../../../../../../constants/roles'
import { checkboxAgreeTermsData } from './checkbox-agree-terms'
import translations from '../../../../../../utils/translations'
import { FieldProperties, FieldData } from '../../field-types-data'
import { FormPlugin } from '../../../../../../constants/plugins'
import { FieldRenderConfigType } from '../../constants'

const makeCheckboxJoinCommunity = (): FieldProperties => {
  const t = translations.t.bind(translations)

  return _.merge({}, checkboxAgreeTermsData.make(), {
    extraData: {
      role: FIELDS.ROLE_FIELD_REGISTRATION_FORM_CHECKBOX_JOIN_COMMUNITY,
      connectionConfig: {
        crmLabel: t(
          `fieldTypes.${RegistrationFieldPreset.REGISTRATION_FORM_CHECKBOX_JOIN_COMMUNITY}`
        ),
      },
      props: {
        required: false,
      },
      data: {
        label: t(
          `preset.${_.camelCase(
            RegistrationFieldPreset.REGISTRATION_FORM_CHECKBOX_JOIN_COMMUNITY
          )}Label`
        ),
      },
    },
  })
}

export const checkboxJoinCommunityData: FieldData = _.merge({}, checkboxAgreeTermsData, {
  make: makeCheckboxJoinCommunity,
  renderConfig: {
    [FormPlugin.REGISTRATION_FORM]: {
      required: FieldRenderConfigType.ENABLED,
      checkedByDefault: FieldRenderConfigType.ENABLED,
    },
  },
  disableCollectionSync: false,
})
