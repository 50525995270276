import { FormsFieldPreset, customTypes  } from '../../../../../../constants/field-types'
import { FieldCollectionType } from '../../../../../../constants/wixcode'
import { FIELDS } from '../../../../../../constants/roles'
import { COMPONENT_TYPES } from '../../component-types'
import translations from '../../../../../../utils/translations'
import { FieldProperties, FieldData } from '../../field-types-data'
import { CRM_TYPES } from '../../../../../../constants/crm-types-tags'
import { iconNames } from '../../icons/types'

const makeGeneralSingleCheckbox = (): FieldProperties => {
  const t = translations.t.bind(translations)
  return {
    collectionFieldType: FieldCollectionType.BOOLEAN,
    componentType: COMPONENT_TYPES.SINGLE_CHECKBOX,
    extraData: {
      role: FIELDS.ROLE_FIELD_CHECKBOX,
      connectionConfig: {
        crmType: CRM_TYPES.CUSTOM_FIELD,
        crmLabel: t(`fieldTypes.${FormsFieldPreset.GENERAL_SINGLE_CHECKBOX}`),
      },
      data: {
        label: t(`preset.${FormsFieldPreset.GENERAL_SINGLE_CHECKBOX}Label`),
      },
    },
  }
}

export const generalSingleCheckboxData: FieldData = {
  make: makeGeneralSingleCheckbox,
  customFields: [customTypes.TEXT],
  icon: iconNames.singleCheckbox,
  titleTranslationKey: `adi.${FormsFieldPreset.GENERAL_SINGLE_CHECKBOX}FieldEditPanel.title`
}
