import { FormsFieldPreset, customTypes  } from '../../../../../../constants/field-types'
import { FieldCollectionType } from '../../../../../../constants/wixcode'
import { FIELDS } from '../../../../../../constants/roles'
import { COMPONENT_TYPES } from '../../component-types'
import translations from '../../../../../../utils/translations'
import { FieldProperties, FieldData } from '../../field-types-data'
import { CRM_TYPES } from '../../../../../../constants/crm-types-tags'
import { iconNames } from '../../icons/types'

export const makeGeneralDropdownOption = () => ({
  type: 'SelectOption',
  metaData: {
    isPreset: false,
    schemaVersion: '1.0',
    isHidden: false,
  },
  value: '',
  text: '',
  description: '',
  disabled: false,
})

const makeGeneralDropdown = (): FieldProperties => {
  const t = translations.t.bind(translations)

  return {
    collectionFieldType: FieldCollectionType.TEXT,
    componentType: COMPONENT_TYPES.COMBOBOX,
    extraData: {
      role: FIELDS.ROLE_FIELD_SELECT,
      connectionConfig: {
        crmType: CRM_TYPES.CUSTOM_FIELD,
        crmLabel: t(`fieldTypes.${FormsFieldPreset.GENERAL_DROP_DOWN}`),
      },
      props: {
        placeholder: {
          value: t(`preset.${FormsFieldPreset.GENERAL_DROP_DOWN}PlaceholderValue`),
          text: t(`preset.${FormsFieldPreset.GENERAL_DROP_DOWN}PlaceholderText`),
        },
      },
      data: {
        placeholder: {
          value: t(`preset.${FormsFieldPreset.GENERAL_DROP_DOWN}PlaceholderValue`),
          text: t(`preset.${FormsFieldPreset.GENERAL_DROP_DOWN}PlaceholderText`),
        },
        options: [
          {
            ...makeGeneralDropdownOption(),
            value: t(`preset.${FormsFieldPreset.GENERAL_DROP_DOWN}FirstOptionsValue`),
            text: t(`preset.${FormsFieldPreset.GENERAL_DROP_DOWN}FirstOptionsText`),
            description: t(`preset.${FormsFieldPreset.GENERAL_DROP_DOWN}FirstOptionsText`),
          },
          {
            ...makeGeneralDropdownOption(),
            value: t(`preset.${FormsFieldPreset.GENERAL_DROP_DOWN}SecondOptionsValue`),
            text: t(`preset.${FormsFieldPreset.GENERAL_DROP_DOWN}SecondOptionsText`),
            description: t(`preset.${FormsFieldPreset.GENERAL_DROP_DOWN}SecondOptionsText`),
          },
          {
            ...makeGeneralDropdownOption(),
            value: t(`preset.${FormsFieldPreset.GENERAL_DROP_DOWN}ThirdOptionsValue`),
            text: t(`preset.${FormsFieldPreset.GENERAL_DROP_DOWN}ThirdOptionsText`),
            description: t(`preset.${FormsFieldPreset.GENERAL_DROP_DOWN}ThirdOptionsText`),
          },
          {
            ...makeGeneralDropdownOption(),
            value: t(`preset.${FormsFieldPreset.GENERAL_DROP_DOWN}ForthOptionsValue`),
            text: t(`preset.${FormsFieldPreset.GENERAL_DROP_DOWN}ForthOptionsText`),
            description: t(`preset.${FormsFieldPreset.GENERAL_DROP_DOWN}ForthOptionsText`),
          },
        ],
      },
    },
  }
}

export const generalDropdownData: FieldData = {
  make: makeGeneralDropdown,
  customFields: [customTypes.TEXT],
  icon: iconNames.dropdown,
  titleTranslationKey: `adi.${FormsFieldPreset.GENERAL_DROP_DOWN}FieldEditPanel.title`
}
