import * as _ from 'lodash'
import { FormsFieldPreset } from '../../../../../../constants/field-types'
import translations from '../../../../../../utils/translations'
import { generalTextData } from '../../general-fields/definitions/general-text'
import { FieldProperties, FieldData } from '../../field-types-data'
import { CRM_TYPES, CRM_TAGS } from '../../../../../../constants/crm-types-tags'
import { iconNames } from '../../icons/types'
import { FormPreset } from '../../../../../../constants/form-types';
import { FieldRenderConfigType } from '../../constants';

export const EMAIL_REGEX = '^.+@.+\\.[a-zA-Z]{2,63}$'

const makeEmail = (): FieldProperties => {
  const t = translations.t.bind(translations)

  return _.merge(generalTextData.make(), {
    extraData: {
      connectionConfig: {
        crmType: CRM_TYPES.EMAIL,
        crmTag: CRM_TAGS.OTHER,
        crmLabel: t(`fieldTypes.${FormsFieldPreset.EMAIL}`),
      },
      data: {
        textType: 'email',
        pattern: EMAIL_REGEX,
        placeholder: t(`fieldTypes.${FormsFieldPreset.EMAIL}`),
      },
      props: {
        placeholder: t(`fieldTypes.${FormsFieldPreset.EMAIL}`),
        required: true,
      },
    },
  })
}

export const emailData: FieldData = {
  make: makeEmail,
  customFields: [],
  icon: iconNames.email,
  renderConfig: {
    [FormPreset.GET_SUBSCRIBERS]: {
      required: FieldRenderConfigType.DISABLED,
      isMandatory: true
    }
  },
  titleTranslationKey: `adi.${FormsFieldPreset.EMAIL}FieldEditPanel.title`
}
