import * as _ from 'lodash'
import { generalNumberData } from '../../general-fields/definitions/general-number'
import { FieldProperties, FieldData } from '../../field-types-data'
import { CRM_TYPES } from '../../../../../../constants/crm-types-tags'

const makeCrmNumber = (): FieldProperties => {
  return _.merge(generalNumberData.make(), {
    extraData: {
      connectionConfig: {
        crmType: CRM_TYPES.CUSTOM_FIELD,
      },
    },
  })
}

export const crmNumberData: FieldData = {
  make: makeCrmNumber,
  customFields: generalNumberData.customFields,
  icon: generalNumberData.icon
}
