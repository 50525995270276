import * as _ from 'lodash'
import { generalUrlData } from '../../general-fields/definitions/general-url'
import { FieldProperties, FieldData } from '../../field-types-data'
import { CRM_TYPES } from '../../../../../../constants/crm-types-tags'
import { iconNames } from '../../icons/types'

const makeCrmURL = (): FieldProperties => {
  return _.merge(generalUrlData.make(), {
    extraData: {
      connectionConfig: {
        crmType: CRM_TYPES.CUSTOM_FIELD,
      },
    },
  })
}

export const crmUrlData: FieldData = {
  make: makeCrmURL,
  customFields: generalUrlData.customFields,
  icon: iconNames.webaddress
}
