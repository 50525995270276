import { FormsFieldPreset } from '../../../../../constants/field-types'
import { firstNameData } from './definitions/first-name'
import { lastNameData } from './definitions/last-name'
import { companyData } from './definitions/company'
import { positionData } from './definitions/position'
import { emailData } from './definitions/email'
import { addressData } from './definitions/address'
import { phoneData } from './definitions/phone'
import { birthdayData } from './definitions/birthady'
import { anniversaryData } from './definitions/anniversary'
import { websiteData } from './definitions/webstie'
import { crmTextData } from './definitions/crm-txt'
import { crmNumberData } from './definitions/crm-number'
import { crmUrlData } from './definitions/crm-url'
import { crmDateData } from './definitions/crm-date'

export const recommendedFields = () => ({
  [FormsFieldPreset.FIRST_NAME]: firstNameData,
  [FormsFieldPreset.LAST_NAME]: lastNameData,
  [FormsFieldPreset.COMPANY]: companyData,
  [FormsFieldPreset.POSITION]: positionData,
  [FormsFieldPreset.EMAIL]: emailData,
  [FormsFieldPreset.ADDRESS]: addressData,
  [FormsFieldPreset.PHONE]: phoneData,
  [FormsFieldPreset.BIRTHDAY]: birthdayData,
  [FormsFieldPreset.ANNIVERSARY]: anniversaryData,
  [FormsFieldPreset.WEBSITE]: websiteData,
  [FormsFieldPreset.CRM_TEXT]: crmTextData,
  [FormsFieldPreset.CRM_NUMBER]: crmNumberData,
  [FormsFieldPreset.CRM_URL]: crmUrlData,
  [FormsFieldPreset.CRM_DATE]: crmDateData,
})
